import cn from 'classnames';
import type {PropsWithChildren, HTMLAttributes} from 'react';
import {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

import {twMerge} from '@/stylesheets/twMerge';
import SvgCancel from '@/components/brochureV2/icons/modules/Cancel';
import {ModalSizeEnum} from '@/enums';
import {useFocusTrap} from '@/hooks/useFocusTrap';
import {useKeyPress} from '@/hooks/useKeyPress';
import {usePreventBodyScroll} from '@/hooks/usePreventBodyScroll';

export enum ModalColorScheme {
  Light = 'light',
  Dark = 'dark',
}

interface Props {
  className?: string;
  closeLabel?: string;
  colorScheme?: ModalColorScheme;
  contentContainerClass?: string;
  handleClose: () => void;
  id: string;
  isActive?: boolean;
  pictureInPicture?: boolean;
  modalSize?: ModalSizeEnum;
}

type ModalProps = PropsWithChildren<Props> & HTMLAttributes<HTMLElement>;

export default function Modal({
  children,
  className = '',
  closeLabel = 'Close modal',
  colorScheme = ModalColorScheme.Dark,
  handleClose,
  id,
  isActive = false,
  pictureInPicture = false,
  modalSize,
  ...props
}: ModalProps) {
  const size = modalSize || ModalSizeEnum.Small;
  const ref = useRef<any>();

  const [onClient, setIsOnClient] = useState(false);
  usePreventBodyScroll(isActive && !pictureInPicture);
  useFocusTrap(ref, isActive && !pictureInPicture);
  useKeyPress('Escape', handleClose);

  useEffect(() => {
    setIsOnClient(true);
  }, []);

  if (!onClient) return null;
  if (!isActive) return null;

  return (
    <>
      {ReactDOM.createPortal(
        <aside
          className={twMerge(
            cn(
              'fixed inset-0 z-modal flex justify-center overflow-auto pt-6',
              {'is-active': isActive},
              {
                'flex align-center items-center':
                  size === ModalSizeEnum.Large || size === ModalSizeEnum.Full,
                'text-white bg-shade-90': colorScheme === ModalColorScheme.Dark,
                'text-base bg-white': colorScheme === ModalColorScheme.Light,
              },
            ),
            className,
          )}
          id={id}
          data-component-name={id}
          ref={ref}
          role="dialog"
          aria-modal
          {...props}
        >
          <div
            className={cn('relative max-h-[66vh]', {
              'max-w-4xl w-[90%]': size === ModalSizeEnum.Large,
              'max-w-screen-lg w-[90%]': size === ModalSizeEnum.Full,
              'max-w-[35em]':
                size !== ModalSizeEnum.Large && size !== ModalSizeEnum.Full,
            })}
          >
            {/*
            we use autoFocus here to ensure focus is in the modal hierarchy, otherwise it may get stuck
            *outside* of it, which assuredly would cause more problems for users navigating using the keyboard
          */}
            <button
              autoFocus
              aria-label={closeLabel}
              className={cn('absolute right-0 py-2 ', {
                'top-0':
                  size !== ModalSizeEnum.Large && size !== ModalSizeEnum.Full,
                '-top-20':
                  size === ModalSizeEnum.Large || size === ModalSizeEnum.Full,
                'hover:[&_svg]:fill-white':
                  colorScheme === ModalColorScheme.Dark,
                'hover:[&_svg]:fill-black':
                  colorScheme === ModalColorScheme.Light,
              })}
              data-component-name="close"
              onClick={handleClose}
              type="button"
            >
              <SvgCancel
                className={cn(
                  'h-5 w-5 transition-colors ease-in-out duration-300',
                  {
                    'fill-shade-30': colorScheme === ModalColorScheme.Dark,
                    'fill-shade-60': colorScheme === ModalColorScheme.Light,
                  },
                )}
              />
            </button>
            <div
              className={cn({
                'pt-8': size === ModalSizeEnum.Small,
              })}
            >
              {children}
            </div>
          </div>
        </aside>,
        document.body,
      )}
    </>
  );
}
